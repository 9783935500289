<template>
  <div id="medical-report-filters-sidebar">
    <b-sidebar id="filters-sidebar" ref="sidebar" title="Sidebar" right shadow no-header>
      <div class="header px-3 py-2">
        <p class="header-title">Filtros</p>
        <Close class="icon" v-b-toggle.filters-sidebar />
      </div>
      <div class="px-3 py-2">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="patient">Pacientes</label>
              <div class="input-search-wrapper">
                <Search class="search" />
                <b-form-input
                  id="patient"
                  class="input"
                  placeholder="Buscar paciente"
                  v-model="form.patient"
                />
              </div
              >
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label v-if="!showPendingStatus" for="date">Data do laudo</label>
              <label v-else for="date">Data do exame</label>
              <Periods
                id="date"
                @select="handlePeriod"
                :clear-selection="!isFormFilled"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="showPendingStatus">
            <b-form-group>
              <SimpleSelect
                label="Situação"
                v-model="form.status"
                :options="statusOptions"
                placeholder="Todos as situações"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label for="doctors">Profissional</label>
              <SearchableMultiselect
                id="professionals"
                :selected="form.professionals"
                placeholder="Todos os profissionais"
                :inputLimit="1"
                :options="professionalOptions"
                trackBy="value"
                label="label"
                @search="searchProfessionals"
                @select="changeSelectedProfessionals"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label for="items">Exames</label>
              <SearchableMultiselect
                id="items"
                :selected="form.items"
                placeholder="Todos os exames"
                :inputLimit="1"
                :options="itemOptions"
                trackBy="value"
                label="label"
                @search="searchItems"
                @input="changeSelectedItems"

              />
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="showPendingStatus">
            <b-form-group>
              <label for="date_limit">Prazo</label>
              <b-form-input
                id="date_limit"
                class="input"
                type="number"
                placeholder="Digite um prazo em dias"
                v-model="form.date_limit"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-button block variant="primary" @click="applyFilters"
        >Confirmar
        </b-button
        >
        <b-button
          v-show="isFormFilled"
          @click="resetForm"
          block
          variant="outline-primary"
        >Redefinir
        </b-button
        >
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import itemsApi from '@items/api'
import { showFeature } from '@/utils/permissionsHelp'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    SimpleSelect: () => import('@/components/General/SimpleSelect'),
    Periods: () => import('@/components/General/Periods'),
    Search: () => import('@/assets/icons/search.svg'),
    SearchableMultiselect: () =>
      import('@/components/General/SearchableMultiselect')
  },
  props: {
    showPendingStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      form: this.getDefaultForm(),
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      dateLimitOptions: [
        { value: 7, label: '7 dias' },
        { value: 3, label: '3 dias' }
      ],
      statusOptions: [
        { value: 'expired', label: 'Expirado' },
        { value: 'expiring', label: 'A expirar' },
        {
          value: 'pending',
          label: 'Pendente'
        }
      ],
      itemOptions: [],
      professionalOptions: []
    }
  },
  computed: {
    isFormFilled() {
      return !isEqual(this.getDefaultForm(), this.form)
    }
  },
  async mounted() {
    switch (this.$route.query.status) {
      case 'expirado':
        this.form.status = 'expired'
        break
      case 'aexpirar':
        this.form.status = 'expiring'
        break
      case 'pendente':
        this.form.status = 'pending'
        break
      default:
        this.form.status = ''
        break
    }
    await this.searchProfessionals(this.user.name)
    if (this.$route.query.doctor) {
      this.form.professionals.push(this.professionalOptions.find(
        professional => professional.value === this.user.id
      ))
    }
    this.applyFilters()
  },
  methods: {
    getDefaultForm() {
      return {
        patient: '',
        status: null,
        period: {
          start: null,
          end: null
        },
        professionals: [],
        items: [],
        date_limit: null
      }
    },
    handlePeriod(period) {
      this.form.period.start = period.start
      this.form.period.end = period.end
    },
    resetForm() {
      this.form = this.getDefaultForm()
      this.applyFilters()
    },
    changeSelectedProfessionals(professionals) {
      this.form.professionals = professionals
    },
    changeSelectedExams(exams) {
      this.form.exams = exams
    },
    changeSelectedItems(items) {
      this.form.items = items
    },
    applyFilters() {
      this.$emit('applyFilters', this.form)
      this.$refs.sidebar.hide()
    },
    async searchExams(query) {
      try {
        const response = await this.api.searchClinicProcedures(
          this.clinic.id,
          query,
          []
        )
        this.examOptions = response.data.map(procedure => {
          return { label: procedure.name, value: procedure.id }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async searchProfessionals(query) {
      try {
        const response = await this.api.getProfessionals(
          1,
          this.clinic.id,
          query
        )
        this.professionalOptions = response.data.data.map(professional => {
          return { label: professional.name, value: professional.id }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async searchItems(query) {
      try {
        const types = ['EXAM']

        if (showFeature('Centro cirúrgico')) {
          types.push('SURGICAL')
        }
        
        const response = await itemsApi.searchItemsByTypeAndClinicId(
          types,
          this.clinic.id,
          query
        )      
        
        this.itemOptions = response.data.map(item => {
          return { label: item.name, value: item.id }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss">
#medical-report-filters-sidebar {
  .b-sidebar {
    top: 11.7%;
    right: 0.8%;
    background-color: var(--background-color);
    height: 88vh;
  }
}
</style>
<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--dark-blue);
}

.icon {
  height: 24px;
  width: 24px;
  fill: var(--neutral-500);
}

.input-search-wrapper {
  width: 100%;
  position: relative;

  .search {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 5px;
    top: 8px;
    stroke: var(--neutral-500);
  }

  .input {
    text-indent: 20px;
  }
}
</style>
